import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_thermometer',
    label: 'LIFT_Thermometer',
    flowVariables: [
        { name: 'zero', label: '(0) Response', type: 'string' },
        { name: 'two', label: '(2) Response', type: 'string' },
        { name: 'four', label: '(4) Response', type: 'string' },
        { name: 'six', label: '(6) Response', type: 'string' },
        { name: 'eight', label: '(8) Response', type: 'string' },
        { name: 'ten', label: '(10) Response', type: 'string' },
    ],
    builderInitialValues,
    builder: Builder,
    player: Player,
};
