import React from 'react';
import IconHourglass from 'cccisd-icons/hour-glass';

export const renderAlert = alertText => {
    return (
        <div className="alert alert-info" style={{ textAlign: 'center', marginTop: '1em' }}>
            <IconHourglass spaceRight />
            {alertText}
        </div>
    );
};

const stressfulEvents = {
    1: {
        Chapter1TriedOut:
            'You tried out for a team, school play, band, or other activity, or performed in front of a group.',
        Chapter1Dating: 'You got into a relationship/started dating someone.',
        Chapter1BrokeUp: 'You broke up with a girlfriend or boyfriend.',
        Chapter1TroubleSomeone: 'You had trouble dealing with someone at school.',
        Chapter1Tests: 'You had a lot of tests and deadlines for school.',
        Chapter1Safety: "Your school, neighborhood, or home didn't feel safe.",
        Chapter1NewHouse: 'You moved to a new house or apartment.',
        Chapter1ChangedSchools: 'You changed schools.',
        Chapter1Financial: 'Your family had financial problems.',
        Chapter1LunchMoney: "You didn't have as much money for lunches or activities as other students.",
        Chapter1ParentsFighting: 'Your parents were fighting.',
        Chapter1ParentsDivorced: 'You parents separated or got divorced.',
        Chapter1PetLost: 'Your pet got lost, was ill or injured, or died.',
        Chapter1PetNew: 'You got a new pet.',
        Chapter1FamilyIll: 'Someone in your family was ill or injured.',
        Chapter1FamilyArrested: 'Someone in your family was arrested, went to jail, or got deported.',
        Chapter1Pregnant: 'You got pregnant or got someone else pregnant.',
        Chapter1MovedOut: 'Someone moved out of your home.',
        Chapter1Trouble: 'You got in trouble at school or with the police.',
        Chapter1Grounded: 'You got grounded or punished by your parents.',
        Chapter1SomeoneNew: 'Someone new came to live in your house (new baby, new step parent).',
        Chapter1SexualRelat: 'You started a sexual relationship with someone.',
        Chapter1College: 'You applied to college or found out whether you got into college.',
        Chapter1NewJob: 'You got a new job or changed jobs.',
        Chapter1SATACT: 'You took the SAT or ACT or got back your scores.',
        Chapter1BadGrade: 'You got a bad grade or grades at school.',
        Chapter1Award: 'You won an award.',
        Chapter1StressNone: 'NONE of these things happened to you.',
    },
    4: {
        Chapter4TriedOut:
            'You tried out for a team, school play, band, or other activity, or performed in front of a group.',
        Chapter4Dating: 'You got into a relationship/started dating someone.',
        Chapter4BrokeUp: 'You broke up with a girlfriend or boyfriend.',
        Chapter4TroubleSomeone: 'You had trouble dealing with someone at school.',
        Chapter4Tests: 'You had a lot of tests and deadlines for school.',
        Chapter4Safety: "Your school, neighborhood, or home didn't feel safe.",
        Chapter4NewHouse: 'You moved to a new house or apartment.',
        Chapter4ChangedSchools: 'You changed schools.',
        Chapter4Financial: 'Your family had financial problems.',
        Chapter4LunchMoney: "You didn't have as much money for lunches or activities as other students.",
        Chapter4ParentsFighting: 'Your parents were fighting.',
        Chapter4ParentsDivorced: 'You parents separated or got divorced.',
        Chapter4PetLost: 'Your pet got lost, was ill or injured, or died.',
        Chapter4PetNew: 'You got a new pet.',
        Chapter4FamilyIll: 'Someone in your family was ill or injured.',
        Chapter4FamilyArrested: 'Someone in your family was arrested, went to jail, or got deported.',
        Chapter4Pregnant: 'You got pregnant or got someone else pregnant.',
        Chapter4MovedOut: 'Someone moved out of your home.',
        Chapter4Trouble: 'You got in trouble at school or with the police.',
        Chapter4Grounded: 'You got grounded or punished by your parents.',
        Chapter4SomeoneNew: 'Someone new came to live in your house (new baby, new step parent).',
        Chapter4SexualRelat: 'You started a sexual relationship with someone.',
        Chapter4College: 'You applied to college or found out whether you got into college.',
        Chapter4NewJob: 'You got a new job or changed jobs.',
        Chapter4SATACT: 'You took the SAT or ACT or got back your scores.',
        Chapter4BadGrade: 'You got a bad grade or grades at school.',
        Chapter4Award: 'You won an award.',
        Chapter4StressNone: 'NONE of these things happened to you.',
    },
    7: {
        Chapter7TriedOut:
            'You tried out for a team, school play, band, or other activity, or performed in front of a group.',
        Chapter7Dating: 'You got into a relationship/started dating someone.',
        Chapter7BrokeUp: 'You broke up with a girlfriend or boyfriend.',
        Chapter7TroubleSomeone: 'You had trouble dealing with someone at school.',
        Chapter7Tests: 'You had a lot of tests and deadlines for school.',
        Chapter7Safety: "Your school, neighborhood, or home didn't feel safe.",
        Chapter7NewHouse: 'You moved to a new house or apartment.',
        Chapter7ChangedSchools: 'You changed schools.',
        Chapter7Financial: 'Your family had financial problems.',
        Chapter7LunchMoney: "You didn't have as much money for lunches or activities as other students.",
        Chapter7ParentsFighting: 'Your parents were fighting.',
        Chapter7ParentsDivorced: 'You parents separated or got divorced.',
        Chapter7PetLost: 'Your pet got lost, was ill or injured, or died.',
        Chapter7PetNew: 'You got a new pet.',
        Chapter7FamilyIll: 'Someone in your family was ill or injured.',
        Chapter7FamilyArrested: 'Someone in your family was arrested, went to jail, or got deported.',
        Chapter7Pregnant: 'You got pregnant or got someone else pregnant.',
        Chapter7MovedOut: 'Someone moved out of your home.',
        Chapter7Trouble: 'You got in trouble at school or with the police.',
        Chapter7Grounded: 'You got grounded or punished by your parents.',
        Chapter7SomeoneNew: 'Someone new came to live in your house (new baby, new step parent).',
        Chapter7SexualRelat: 'You started a sexual relationship with someone.',
        Chapter7College: 'You applied to college or found out whether you got into college.',
        Chapter7NewJob: 'You got a new job or changed jobs.',
        Chapter7SATACT: 'You took the SAT or ACT or got back your scores.',
        Chapter7BadGrade: 'You got a bad grade or grades at school.',
        Chapter7Award: 'You won an award.',
        Chapter7StressNone: 'NONE of these things happened to you.',
    },
};

const traumaticEvents = {
    1: {
        Chapter1InAccident:
            'You were in a serious accident or natural disaster where you could have been badly hurt or killed.',
        Chapter1SeenAccident:
            "You've seen a serious accident where someone could have been (or was) badly hurt or died.",
        Chapter1SomeoneSick: 'Someone close to you was very sick or injured.',
        Chapter1SomeoneDied: 'Someone close to you died.',
        Chapter1Hospital: 'You had a serious illness or injury or had to be rushed to the hospital.',
        Chapter1DogAttack: 'You were attacked by a dog or other animal.',
        Chapter1Hurt: 'Someone told you they were going to hurt you.',
        Chapter1BeenSlapped: "You've been slapped, punched, hit, or beaten up by someone.",
        Chapter1SeenSlapped: "You've seen someone being slapped, punched, hit, or beaten up by someone else.",
        Chapter1BeenThreatened: "You've been threatened or attacked with a weapon (gun or knife).",
        Chapter1SeenThreatened: "You've seen someone else being threatened or attacked with a weapon (gun or knife).",
        Chapter1TraumaNone: 'NONE of these things happened to you.',
    },
};

export const getSelectedEvents = (resp, chap) => {
    const results = [];

    for (const [key, value] of Object.entries(resp)) {
        if (value === '1' && stressfulEvents[chap][key]) {
            results.push(stressfulEvents[chap][key]);
        }
    }

    return results;
};

export const getSelectedTraumaticEvents = (resp, chap) => {
    const results = [];

    for (const [key, value] of Object.entries(resp)) {
        if (value === '1' && traumaticEvents[chap][key]) {
            results.push(traumaticEvents[chap][key]);
        }
    }

    return results;
};

export const getTraumaUpsetLevel = score => {
    const upsetLevel = {
        0: 'Not At All Upsetting',
        1: 'Somewhat Upsetting',
        2: 'Quite Upsetting',
        3: 'Extremely Upsetting',
    };

    return upsetLevel[score];
};

export const getStressLabel = num => {
    if (num <= 4) {
        return 'LOW';
    }
    if (num > 4 && num <= 7) {
        return 'MEDIUM';
    }
    return 'HIGH';
};

export const makeTealBlocks = level => {
    const liftGray = 'gray';
    const liftTeal = '#3ebca4';

    const grayBlocks = Array(10 - level).fill(liftGray);
    const tealBlocks = Array(level).fill(liftTeal);

    const allBlocks = [...tealBlocks, ...grayBlocks];

    return allBlocks;
};

export const liftTeal = '#3ebca4';
export const liftRed = '#ce8181';

export const activeChapters = {
    1: { id: 'Chapter_1', Chapter_1: true, Chapter_4: false, Chapter_7: false },
    4: { id: 'Chapter_4', Chapter_1: true, Chapter_4: true, Chapter_7: false },
    7: { id: 'Chapter_7', Chapter_1: true, Chapter_4: true, Chapter_7: true },
};

export const showTraumaLevel = total => {
    const level = {};

    if (total <= 9) {
        level.label = 'Doing Well';
        level.text = "You're handling the trauma you’ve experienced pretty well.";
    } else if (total >= 10 && total <= 13) {
        level.label = 'A Little Trouble';
        level.text = "It looks like you're having some trouble handling the trauma you’ve experienced.";
    } else if (total >= 14 && total <= 24) {
        level.label = 'Some Problems';
        level.text =
            'It looks like you’re having some real problems handling the trauma you’ve experienced. The LIFT course will help, but you might want to seek additional support to help you get through it.';
    } else {
        level.label = 'Many Problems';
        level.text =
            "It looks like you're having a lot of trouble handling the trauma you’ve experienced. The LIFT course could be helpful, but you should also be working with a counselor or therapist to help get you through it.";
    }

    return level;
};

export const showDepressionLevel = total => {
    const level = {};

    if (total <= 11) {
        level.label = 'Doing Well';
        level.text = 'Your score shows that your mood is similar to other teens.';
    } else if (total === 12 || total === 13) {
        level.label = 'Some Mood Problems';
        level.text = "Your score shows that you're having more mood problems than other teens.";
    } else if (total >= 14) {
        level.label = 'Mood Problems';
        level.text = "Your score shows that you're having more mood problems than other teens.";
    }

    return level;
};

export const showAnxietyLevel = total => {
    const level = {};

    if (total <= 10) {
        level.label = 'Doing Well';
        level.text = 'Your score shows that your anxiety is similar to other teens.';
    } else if (total === 12 || total === 13) {
        level.label = 'Getting Stressed';
        level.text = 'Your score shows that your anxiety is a little higher than other teens.';
    } else if (total >= 14) {
        level.label = 'Mood Problems';
        level.text =
            'Your score shows that your anxiety is higher than other teens. It would be good to seek support from a counselor.';
    }

    return level;
};

export const shortenResponse = (str, charLength) => {
    if (!str) {
        return '';
    }
    if (str.length > charLength) {
        let trimmedString = str.substring(0, charLength);
        // don't cut off string in the middle of a word
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
        return trimmedString + '...';
    }
    return str;
};

export const oftenThinkMap = {
    Chapter3OftenGuard: {
        label: "It's important to be on guard (careful) all the time.",
        helpfulDataConnector: 'row_1_response',
    },
    Chapter3OftenHappenNext: {
        label: "It's important to be really careful because you never know what will happen next.",
        helpfulDataConnector: 'row_4_response',
    },
    Chapter3OftenProblems: {
        label: 'I can’t deal with everyday problems.',
        helpfulDataConnector: 'row_7_response',
    },
    Chapter3OftenDangerous: { label: 'The world is a dangerous place.', helpfulDataConnector: 'row_6_response' },
    Chapter3OftenBadThings: {
        label: "I can't deal with thinking or talking about bad things.",
        helpfulDataConnector: 'row_8_response',
    },
    Chapter3OftenResponsibilities: {
        label: "I'm not good at handling my responsibilities (homework, chores, taking care of pets).",
        helpfulDataConnector: 'row_10_response',
    },
    Chapter3OftenBadThingsHappen: { label: 'Bad things always happen.', helpfulDataConnector: 'row_12_response' },
};

export const neverThinkMap = {
    Chapter3NeverHandleThings: {
        label: 'I can handle most things that come my way.',
        helpfulDataConnector: 'row_2_response',
    },
    Chapter3Calming: {
        label: "I'm good at calming myself down when I feel upset.",
        helpfulDataConnector: 'row_3_response',
    },
    Chapter3NeverTrust: { label: 'I have people in my life I can trust.', helpfulDataConnector: 'row_5_response' },
    Chapter3OftenSafe: {
        label: "Most of the time it's safe to be around people I don't know that well.",
        helpfulDataConnector: 'row_9_response',
    },
    Chapter3NeverTrustCare: {
        label: 'I can trust other people to take care of me.',
        helpfulDataConnector: 'row_11_response',
    },
    Chapter3NeverBrave: { label: 'Overall, I am brave.', helpfulDataConnector: 'row_13_response' },
    Chapter3NeverHandleMyself: {
        label: "If something bad happened, I'd be able to handle myself (my feelings) well.",
        helpfulDataConnector: 'row_14_response',
    },
};
