import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_c3_self_care_plan',
    label: 'LIFT_C3_Self_Care_Plan',
    flowVariables: [{ name: 'self_care_plan_response', label: 'Self-Care Plan Response', type: 'string' }],
    builderInitialValues,
    builder: Builder,
    player: Player,
};
