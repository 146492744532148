import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_c3_neg_pos_thoughts',
    label: 'LIFT_C3_Neg_Pos_Thoughts',
    flowVariables: [
        { name: 'row_1_response', label: 'Row 1 Response', type: 'string' },
        { name: 'row_2_response', label: 'Row 2 Response', type: 'string' },
        { name: 'row_3_response', label: 'Row 3 Response', type: 'string' },
        { name: 'row_4_response', label: 'Row 4 Response', type: 'string' },
        { name: 'row_5_response', label: 'Row 5 Response', type: 'string' },
        { name: 'row_6_response', label: 'Row 6 Response', type: 'string' },
        { name: 'row_7_response', label: 'Row 7 Response', type: 'string' },
        { name: 'row_8_response', label: 'Row 8 Response', type: 'string' },
        { name: 'row_9_response', label: 'Row 9 Response', type: 'string' },
        { name: 'row_10_response', label: 'Row 10 Response', type: 'string' },
        { name: 'row_11_response', label: 'Row 11 Response', type: 'string' },
        { name: 'row_12_response', label: 'Row 12 Response', type: 'string' },
        { name: 'row_13_response', label: 'Row 13 Response', type: 'string' },
        { name: 'row_14_response', label: 'Row 14 Response', type: 'string' },
    ],
    builderInitialValues,
    builder: Builder,
    player: Player,
};
