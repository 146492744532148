import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import { renderAlert, getSelectedTraumaticEvents, getTraumaUpsetLevel, liftRed } from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';

import IconCheck from 'cccisd-icons/checkbox-checked.js';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [reportSettings, setReportSettings] = useState({
        events: [],
        eventsTotal: 0,
        label: '',
    });

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.chapter1;

        const answers = getSelectedTraumaticEvents(data, '1');

        if (answers.length > 0) {
            setHasModuleProgress(true);
        } else {
            setHasModuleProgress(false);
        }

        setReportSettings({
            events: answers,
            eventsTotal: response.data.flows.assignmentProgress.level.Chapter1TraumaticLifeEvents,
            label: getTraumaUpsetLevel(response.data.flows.assignmentProgress.level.Chapter1TraumaUpset),
        });
        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report height="600px" downloadFilename={`LIFT_Chapter_1_${props.settings.chapterTitle}_Report`}>
                <Page>
                    <div className={style.page}>
                        <ReportHeader chapterId="1" chapterTitle={props.settings.chapterTitle} />
                        <div>
                            {hasModuleProgress ? (
                                <>
                                    <div className={style.top}>
                                        <div className={style.label}>
                                            Your <span style={{ color: liftRed }}>traumatic</span> events
                                        </div>
                                        <div className={style.total}>{reportSettings.eventsTotal}</div>
                                        <div className={style.feedback}>
                                            You said {reportSettings.eventsTotal} things happened to you and that the
                                            worst was {reportSettings.label}.
                                        </div>
                                    </div>
                                    <div className={style.bottom}>
                                        {reportSettings.events.map((e, i) => {
                                            return (
                                                <div key={i} className={style.event}>
                                                    <span style={{ color: liftRed, fontSize: '2.25em' }}>
                                                        <IconCheck spaceRight />
                                                    </span>
                                                    {e}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            ) : (
                                renderAlert('No events were selected yet.')
                            )}
                        </div>
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
