import { AnswerSummary } from 'cccisd-laravel-appdefs/dist/reportTemplates';
import STATM2RiskFactors from './STATM2RiskFactors';
import STATM3MySTATLevelTimepoints from './STATM3MySTATLevelTimepoints';
import STATM5SMARTgoal from './STATM5SMARTgoal';
import LIFTStressfulDynamic from './LIFT_Stressful_Dynamic';
import LIFTTraumaticDynamic from './LIFT_Traumatic_Dynamic';
import LIFTDepAnxDynamic from './LIFT_Dep_Anx_Dynamic';
import LiftC1Traumatic from './LIFT_C1_Traumatic';
import LIFTThermometer from './LIFT_Thermometer';
import LIFTC3NegPosThoughts from './LIFT_C3_Neg_Pos_Thoughts';
import LIFTC3SelfCarePlan from './LIFT_C3_Self_Care_Plan';
import LIFTCTraumaThermometer from './LIFT_C4_TraumaThermometer';
import LIFTC5Newspaper from './LIFT_C5_Newspaper';
import LIFTC2SMART from './LIFTC2SMART';

export default [
    AnswerSummary,
    STATM2RiskFactors,
    STATM3MySTATLevelTimepoints,
    STATM5SMARTgoal,
    LIFTStressfulDynamic,
    LIFTTraumaticDynamic,
    LIFTDepAnxDynamic,
    LiftC1Traumatic,
    LIFTThermometer,
    LIFTC3NegPosThoughts,
    LIFTC3SelfCarePlan,
    LIFTCTraumaThermometer,
    LIFTC5Newspaper,
    LIFTC2SMART,
];
