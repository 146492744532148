import React from 'react';
import PropTypes from 'prop-types';

import style from './style.css';
import logo from '../../images/LIFT/LIFT_logo.svg';

const ReportHeader = props => {
    const { chapterId, chapterTitle } = props;

    return (
        <div className={style.container}>
            <div className={style.chapterId}>{`Chapter ${chapterId}`}</div>
            <div className={style.chapterTitle} style={{ color: 'black' }}>
                {chapterTitle}
            </div>
            <img src={logo} alt="LIFT logo" className={style.logo} />
        </div>
    );
};

ReportHeader.propTypes = {
    chapterId: PropTypes.string,
    chapterTitle: PropTypes.string,
};

export default ReportHeader;
