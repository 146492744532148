import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { ResponsiveLine } from 'cccisd-nivo/line';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import { activeChapters, showTraumaLevel, renderAlert, liftTeal } from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [reportSettings, setReportSettings] = useState({
        score: 0,
        label: '',
        text: '',
    });
    let chapterId = props.settings?.chapter || '1';

    useEffect(() => {
        getData();
    }, [chapterId]);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTags;
        delete data.__typename;

        if (Object.values(data).length > 0) {
            setHasModuleProgress(true);
        } else {
            setHasModuleProgress(false);
        }

        const chapterHandle = activeChapters[chapterId].id;
        const chapterScore = Number(data[chapterHandle]);
        setReportSettings({
            score: chapterScore,
            label: showTraumaLevel(chapterScore).label,
            text: showTraumaLevel(chapterScore).text,
        });

        const results = [];

        for (const [chapter, score] of Object.entries(data)) {
            let isActive = activeChapters[chapterId][chapter];

            results.push({
                y: isActive ? score : null,
                x: chapter.split('_').join(' '),
            });
        }

        setChartData(results);
        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    if (!props.settings.chapter) {
        return renderAlert('Please select a Report Chapter in Report Settings');
    }

    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report height="600px" downloadFilename={`LIFT_Chapter_${chapterId}_${props.settings.chapterTitle}_Report`}>
                <Page>
                    <div className={style.page}>
                        <ReportHeader chapterId={chapterId} chapterTitle={props.settings.chapterTitle} />
                        {hasModuleProgress ? (
                            <div className={style.report}>
                                <div className={style.aboveChart}>
                                    <div className={style.reportScore}>
                                        Your Score:{' '}
                                        <div className={style.score}>
                                            <span style={{ color: liftTeal }}>{reportSettings.score}/</span>51
                                        </div>
                                    </div>
                                    <div className={style.label}>{reportSettings.label}</div>
                                    <div className={style.text}>{reportSettings.text}</div>
                                </div>
                                <div style={{ width: '100%', height: '300px' }}>
                                    <ResponsiveLine
                                        animate
                                        data={[
                                            {
                                                id: 'trauma-related',
                                                data: chartData,
                                            },
                                        ]}
                                        enablePointLabel
                                        colors={[liftTeal]}
                                        yScale={{
                                            max: 51,
                                            min: 0,
                                            type: 'linear',
                                        }}
                                        legends={[]}
                                        axisLeft={{
                                            legend: 'Trauma Problems',
                                            legendPosition: 'middle',
                                            legendOffset: -70,
                                            tickPadding: 30,
                                        }}
                                        margin={{
                                            top: 20,
                                            left: 110,
                                            right: 60,
                                            bottom: 60,
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            renderAlert('No events were selected yet.')
                        )}
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
