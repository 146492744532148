import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import _get from 'lodash/get';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import { shortenResponse } from '../../common/reportHelpers.js';

import IconHourglass from 'cccisd-icons/hour-glass';

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [responses, setResponses] = useState({
        heading: '',
        story: '',
    });
    const reportTitle = props.settings?.chapterTitle;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let heading = _get(props, 'variables.heading', '');
        let story = _get(props, 'variables.story', '');

        setResponses({
            heading: shortenResponse(heading, 60),
            story: shortenResponse(story, 1050),
        });

        setHasModuleProgress(!!story);

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report height="600px" downloadFilename={`LIFT_Chapter_5_${reportTitle}_Report`}>
                <Page>
                    <div className={style.page}>
                        <ReportHeader chapterId="5" chapterTitle={reportTitle} />
                        {hasModuleProgress ? (
                            <div className={style.body}>
                                <p>
                                    If you&apos;d like to save or print a copy of your story, please do so now. Your
                                    story will be erased when you close this browser and will not be saved to your
                                    binder. You can use the box below to draw a picture to go with your story if
                                    desired.
                                </p>
                                <div className={style.drawing} />
                                <h3>{responses.heading}</h3>
                                <div className={style.story} style={{ maxHeight: '365px', overflow: 'hidden' }}>
                                    {responses.story.split('\n').map(substr => (
                                        <p>{substr}</p>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="alert alert-info" style={{ textAlign: 'center', marginTop: '1em' }}>
                                <IconHourglass spaceRight />
                                No response provided yet
                            </div>
                        )}

                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
