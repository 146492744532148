import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import _get from 'lodash/get';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';

import IconHourglass from 'cccisd-icons/hour-glass';

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [response, setResponse] = useState('');

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        let res = _get(props, 'variables.self_care_plan_response', '');
        setResponse(res);
        setHasModuleProgress(!!res);

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report height="600px" downloadFilename={`LIFT_Chapter_3_${props.settings.chapterTitle}_Report`}>
                <Page>
                    <div className={style.page}>
                        <ReportHeader chapterId="3" chapterTitle={props.settings.chapterTitle} />
                        <div className={style.container}>
                            <div>
                                {`Now that you've learned how to replace negative thoughts with helpful ones, spend a few days, or
                        even a week, practicing this as well as the relaxation exercises and fun activities from your
                        Self-Care Plan. You can use the worksheet below to record any negative thoughts you have and
                        come up with helpful thoughts.`}
                            </div>
                            <div>
                                <h4>Your Personal Self-Care Plan:</h4>
                                <div className={style.response}>
                                    {hasModuleProgress ? (
                                        response
                                    ) : (
                                        <div
                                            className="alert alert-info"
                                            style={{ textAlign: 'center', marginTop: '1em' }}
                                        >
                                            <IconHourglass spaceRight />
                                            No response provided yet
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <h4>Real-Life Practice: Replacing Negative Thoughts</h4>
                                <div>
                                    Print this worksheet, and when something stressful happens, recognize your negative
                                    thoughts and write a few of them in the first column. Then write helpful thoughts to
                                    replace the negative ones. If you have these negative thoughts often, use some of
                                    the helpful thoughts you came up with in this course to argue against them.
                                </div>
                            </div>
                            <div className={style.thoughts}>
                                <div className={style.label}>
                                    My Negative Thoughts
                                    <div>
                                        {new Array(6).fill(' ').map((_, i) => {
                                            return <div key={i} className={style.emptyBox} />;
                                        })}
                                    </div>
                                </div>
                                <div className={style.label}>
                                    My Helpful Thoughts
                                    <div>
                                        {new Array(6).fill(' ').map((_, i) => {
                                            return <div key={i} className={style.emptyBox} />;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
