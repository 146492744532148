import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'lift_c2_smart_goal',
    label: 'LIFT_C2_SMART',
    flowVariables: [
        { name: 's', label: '(S) Response', type: 'string' },
        { name: 'm', label: '(M) Response', type: 'string' },
        { name: 'a', label: '(A) Response', type: 'string' },
        { name: 'r', label: '(R) Response', type: 'string' },
        { name: 't', label: '(T) Response', type: 'string' },
        { name: 'smart', label: '(SMART) Response', type: 'string' },
    ],
    builderInitialValues,
    builder: Builder,
    player: Player,
};
